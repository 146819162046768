<template>
  <div class="app-container">
    <!-- 查询区域 -->
    <div>
      <el-form layout="inline">
        <el-row :gutter="24">
          <el-col :md="6" :sm="8">
            <el-button @click="selectDataListButton()">查询</el-button>
          </el-col>
          <el-col :md="6" :sm="8"> </el-col>
        </el-row>
      </el-form>
    </div>
    <!-- 按钮区 -->
    <el-row :gutter="20" style="margin: 9px 2px">
      <el-button type="success" icon="el-icon-add" size="min" @click="addButton"
        >新增</el-button
      >
    </el-row>

    <!--     列表区域   -->
    <el-table :data="dataColumnList" @row-dblclick="dataBackFillGoods">
      <el-table-column label="序号" align="center" type="index" width="65" />
      <el-table-column
        label="操作"
        align="center"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-edit"
            size="min"
            @click="reimburesLogButton(scope.row)"
            >查看流程</el-button
          >
          <el-button
            type="text"
            icon="el-icon-edit"
            size="min"
            v-if="scope.row.reviewStatus==null||scope.row.reviewStatus==3"
            @click="editButton(scope.row)"
            >编辑</el-button
          >
          <el-button
            type="text"
            icon="el-icon-edit"
            size="min"
            v-if="scope.row.reviewStatus==null||scope.row.reviewStatus==3"
            @click="submitButton(scope.row)"
            >提交</el-button
          >
        </template>
      </el-table-column>
      <el-table-column label="流程编码" prop="code" />
      <el-table-column label="报销日期" prop="times" />
      <el-table-column label="报销金额" prop="realityMoney" />
      <el-table-column label="开始时间" prop="startTime" />
      <el-table-column label="结束时间" prop="endTime" />
      
      <el-table-column label="项目归属" prop="projectCode" />
      <!-- <el-table-column label="状态" prop="status" /> -->
      <el-table-column label="审核状态" prop="reviewStatus"         :formatter="reviewStatusFormat"/>

    </el-table>

    <pagination
      :total="queryParams.total"
      :page-size.sync="queryParams.pageSize"
      :current-page.sync="queryParams.currentPage"
    />

    <addEdit ref="addEditFrom" />
    <reimburesLog ref="reimburesLogFrom" />
  </div>
</template>


<script>
import reimburesLog from "./modules/reimburesLog";
import addEdit from "./modules/addEditreimburse";
import { listreimburseApi,submitReimburseApi } from "@/api/work/project/reimburse";

export default {
  components: {
    addEdit,
    reimburesLog
  },
  data() {
    return {
      // 选中数组
      radio: null,
      ids: null,
      // 查询参数
      queryParams: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      total: 0,
      // 表单参数
      form: {},
      // 表单校验
      rules: {},
      optFrom: {},
      dataColumnList: [],
    };
  },
  watch: {
    "queryParams.currentPage"(val) {
      this.selectDataListButton();
    },
    "queryParams.pageSize"(val) {
      this.selectDataListButton();
    },
  },
  created() {
    this.selectDataListButton();
  },
  methods: {
    selectDataListButton() {
      listreimburseApi().then((res) => {
        this.dataColumnList = res.result.records;
        this.queryParams.total = res.result.total;
      });
    },
    getCurrentRow(row) {
      // 获取选中数据   row表示选中这一行的数据，可以从里面提取所需要的值
      this.ids = row.id;
      this.optFrom = row;
    },
    addButton() {
      this.$refs.addEditFrom.show(null, 1);
    },
    editButton(row) {
      if (row.code == null) return this.$message.error("请选择有效的一列");
      if (row.reviewStatus == 2||row.reviewStatus==6) return this.$message.error("已经提交中,不能修改");
      this.$refs.addEditFrom.show(row.code, 2);
    },
    reimburesLogButton(row) {
      if (row.code == null) return this.$message.error("请选择有效的一列");
      this.$refs.reimburesLogFrom.show(row, 2);
    },
    delectButton() {
      if (this.ids == null) return this.$message.error("请选择有效的一列");
    },
    selectButton() {
      if (this.ids == null) return this.$message.error("请选择有效的一列");
    },
    primaryRest() {
      this.$refs["queryParams"].resetFields();
      this.selectDataListButton();
    },
    //这里是双击事件

    dataBackFillGoods(row, event, column) {
      console.log("双击事件", row, event, column);
      this.$refs.addEditFrom.show(row.code, 3);
    },
    submitButton(row){
      submitReimburseApi({code:row.code}).then(res=>{
        this.selectDataListButton();
      })
    },
    reviewStatusFormat(row, column) {
      if (row.reviewStatus == 1) {
        return "草稿";
      }
      if (row.reviewStatus == 2||row.reviewStatus == 6) {
        return "流程中";
      }
      if (row.reviewStatus == 3) {
        return "退回";
      }
      if (row.reviewStatus == 4) {
        return "拒绝";
      }
      if (row.reviewStatus == 5) {
        return "以接受";
      }
      if (row.reviewStatus == 7) {
        return "流程结束";
      }
    },

  },
};
</script>



